.diff {
	display: inline-block;
}
.diff-level {
	margin-left: 1.6em;
}
.diff-holder {
	color: #666;
	margin: 0;
}
.diff-holder span {
	color: #AAA;
}
del.diff {
	text-decoration: none;
	color: #b30000;
	background: #fadad7;
}
ins.diff {
	background: #eaf2c2;
	color: #406619;
	text-decoration: none;
}
del.diff-key {
	border: 1px solid #f8a4a4;
}
ins.diff-key {
	border: 1px solid #a3ce4c;
	margin-top: -1px;
	position: relative;
}
ins.diff span {
	color: #AABF40;
}
del.diff span {
	color: #EE8177;
}
.audit-obj {
    max-height: 300px;
    max-width: 300px;
    overflow: auto;
}
